function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {
        $('.services-and-references .references .references-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            fade: true,
            cssEase: 'linear',
            adaptiveHeight: true,
        });

        $('.prev-reference').on('click', function() {
            $('.services-and-references .references .references-slider').slick('slickPrev');
        });

        $('.next-reference').on('click', function() {
            $('.services-and-references .references .references-slider').slick('slickNext');
        });
    });
})(jQuery);
